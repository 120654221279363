import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { BackButton, InviteDropDown, NextButton, RoleContractRequirements, SubTitle } from '../../components'
import { StoreContext } from '../../store'
import { DropDownType, IUserDetailOrganisation } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import {
  arrayIsEmpty,
  sortIDropDownOptionsByDisabledStatusAndValue,
  toDropDownOptions,
  toStringArray,
} from '../../utils/helpers'
import i18n from '../../utils/i18n'

interface IProp {
  organisationData: IUserDetailOrganisation
}

const EditStep2Component: React.FC<IProp> = ({ organisationData }) => {
  const {
    userDetailsStore,
    userStore,
    userDetailsStore: { pendingRequest },
  } = useContext(StoreContext)
  const { orgid, userid } = useParams()

  const { t } = useTranslation()

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/2`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { organizationUser, organization } = organisationData
  const {
    customerNumbers,
    setSelectedCustomerNumbers,
    LSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
    principalIds,
    setSelectedPrincipalIds,
  } = userDetailsStore

  const { user } = userStore

  const {
    selectedRoles,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
    selectedPrincipalIds,
  } = organizationUser

  const getCustomerNumbers = useCallback(() => {
    return businessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }, [businessPartnerNumbers, customerNumbers])

  const selectCustomerNumbers = useCallback(
    (values) => {
      setSelectedCustomerNumbers(
        organization.businessId,
        toStringArray(values.filter((number) => number.type === DropDownType.CUSTOMER_NUMBER))
      )
      setSelectedBusinessPartnerNumbers(
        organization.businessId,
        toStringArray(values.filter((number) => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
      )
    },
    [organization.businessId, setSelectedBusinessPartnerNumbers, setSelectedCustomerNumbers]
  )

  const sendChangesPossible: boolean = useMemo(() => {
    // if there are no customer numbers or lcs numbers
    if (!customerNumbers && !LSCNumbers && !transportIds && !principalIds) {
      return true
    }

    const roles = userStore.getRolesThatRequireContracts(organization.businessId, selectedRoles)

    const selectedTypes = []
    if (!arrayIsEmpty(selectedCustomerNumbers.slice())) {
      selectedTypes.push(DropDownType.CUSTOMER_NUMBER)
    }
    if (!arrayIsEmpty(selectedLogisticsContractNumbers.slice())) {
      selectedTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
    }
    if (!arrayIsEmpty(selectedTransportIds.slice())) {
      selectedTypes.push(DropDownType.TRANSPORT_ID)
    }
    if (!arrayIsEmpty(selectedBusinessPartnerNumbers.slice())) {
      selectedTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
    }
    if (!arrayIsEmpty(selectedPrincipalIds.slice())) {
      selectedTypes.push(DropDownType.PRINCIPAL_ID)
    }

    let requirementsOk = true
    roles.forEach((role) => {
      const requiredTypes = []
      if (role.customerNumberRequired) {
        requiredTypes.push(DropDownType.CUSTOMER_NUMBER)
      }
      if (role.logisticsContractNumberRequired) {
        requiredTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
      }
      if (role.transportIdRequired) {
        requiredTypes.push(DropDownType.TRANSPORT_ID)
      }
      if (role.businessPartnerNumberRequired) {
        requiredTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
      }
      if (role.principalIdRequired) {
        requiredTypes.push(DropDownType.PRINCIPAL_ID)
      }

      const intersection = requiredTypes.filter((item) => selectedTypes.indexOf(item) !== -1)
      if (intersection.length === 0) {
        requirementsOk = false
        return
      }
    })

    return requirementsOk
  }, [
    customerNumbers,
    LSCNumbers,
    transportIds,
    principalIds,
    organization.businessId,
    selectedBusinessPartnerNumbers,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedPrincipalIds,
    selectedRoles,
    userStore,
  ])

  const handleNext = useCallback(async () => {
    const shouldRefreshToken = organizationUser.accountId === user.accountId
    await userDetailsStore.postChangesToBackend(organization.businessId, shouldRefreshToken)
    userDetailsStore.setStep(3)
  }, [organization.businessId, organizationUser.accountId, user.accountId, userDetailsStore])

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  return (
    <ContentSection>
      {(customerNumbers || LSCNumbers || transportIds || principalIds) && (
        <SubTitle as="h3">{t(`invite.customerNumbersContracts`)}</SubTitle>
      )}

      <RoleContractRequirements businessId={organization.businessId} roleValues={selectedRoles} />

      <DropdownGrid>
        {(customerNumbers?.length > 0 || businessPartnerNumbers?.length > 0) && (
          <InviteDropDown
            id="customer_number_dropdown"
            key="customer_number_dropdown"
            title={t(`general.selectCustomerNumbers`)}
            placeHolderText={t(`general.customerNumber`)}
            options={getCustomerNumbers()}
            selectedOptions={toDropDownOptions(
              selectedBusinessPartnerNumbers.concat(selectedCustomerNumbers),
              businessPartnerNumbers.concat(customerNumbers).slice().sort(sortIDropDownOptionsByDisabledStatusAndValue),
              allOption
            )}
            onChange={selectCustomerNumbers}
          />
        )}
        {LSCNumbers?.length > 0 && (
          <InviteDropDown
            id="lsc_number_dropdown"
            key="lsc_number_dropdown"
            title={t(`general.selectLogisticsContractNumbers`)}
            placeHolderText={t(`general.LCSNumber`)}
            options={LSCNumbers?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={toDropDownOptions(selectedLogisticsContractNumbers, LSCNumbers, allOption)}
            onChange={(values) => setSelectedLSCNumbers(organization.businessId, toStringArray(values))}
          />
        )}
        {transportIds?.length > 0 && (
          <InviteDropDown
            id="transport_id_dropdown"
            key="transport_id_dropdown"
            title={t(`general.selectTransportIds`)}
            placeHolderText={t(`general.transportId`)}
            options={transportIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={toDropDownOptions(selectedTransportIds, transportIds, allOption)}
            onChange={(values) => setSelectedTransportIds(organization.businessId, toStringArray(values))}
          />
        )}
        {principalIds?.length > 0 && (
          <InviteDropDown
            id="pid_number_dropdown"
            key="pid_number_dropdown"
            title={t(`general.selectPrincipalIds`)}
            placeHolderText={t(`general.principalId`)}
            options={principalIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={toDropDownOptions(selectedPrincipalIds, principalIds, allOption)}
            onChange={(values) => setSelectedPrincipalIds(organization.businessId, toStringArray(values))}
          />
        )}
      </DropdownGrid>

      <StyledButtonsWrapper>
        <BackButton
          onClick={() => {
            userDetailsStore.setStep(1)
          }}
        />

        <NextButton
          loading={!!pendingRequest}
          customText={t(`general.makeChanges`)}
          disabled={!sendChangesPossible}
          onClick={handleNext}
        />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const EditStep2 = observer(EditStep2Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`

const DropdownGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  width: 100%;
`
