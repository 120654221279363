import { Expander } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'
import { DropDownType, IOrganizationItem } from '../../../store/dataModels/interfaces'
import { ContentSection } from '../../../style/layout'
import { addValuesToLabels } from '../../../utils/helpers'
import { ExpanderContractNumberList, MainUserRadioGroup, SelectRoles, SubTitle } from '../..'
import { EditRemove } from './EditDeleteComponent'

interface IProps {
  organization: IOrganizationItem
  selectedRoleValues: string[]
  toggleUserRole?: (role: string) => any
  selectedCustomerNumbers?: string[]
  selectedLCNumbers?: string[]
  selectedTransportIds?: string[]
  selectedBusinessPartnerNumbers?: string[]
  selectedPrincipalIds?: string[]
  editable?: boolean
  accountId?: string
  userEmail?: string
}

export const OrganisationExpanderComponent: React.FC<IProps> = ({
  organization,
  organization: {
    name,
    businessId,
    customerNumbers,
    logisticsContractNumbers,
    transportIds,
    businessPartnerNumbers,
    principalIds,
  },
  selectedRoleValues,
  toggleUserRole,
  selectedCustomerNumbers,
  selectedLCNumbers,
  selectedTransportIds,
  selectedBusinessPartnerNumbers,
  selectedPrincipalIds,
  editable,
  accountId,
  userEmail,
}) => {
  const { layoutStore } = useContext(StoreContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()

  const userCustomerNumbers = customerNumbers.filter((c) => selectedCustomerNumbers.includes(c.value))
  const userLCNumbers = logisticsContractNumbers.filter((c) => selectedLCNumbers.includes(c.value))
  const userTransportIds = transportIds.filter((c) => selectedTransportIds.includes(c.value))
  const userBusinessPartnerNumbers = businessPartnerNumbers.filter((c) =>
    selectedBusinessPartnerNumbers.includes(c.value)
  )
  const userPrincipalIds = principalIds.filter((c) => selectedPrincipalIds.includes(c.value))

  return (
    <Expander isBrand open={isExpanded} hasShadow hasRoundedCorners style={{ flexGrow: 1 }}>
      <Expander.Title
        style={{ padding: '1rem' }}
        isBrand
        open={isExpanded}
        aria-controls="content"
        aria-expanded={isExpanded}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <SubTitle as="div">{`${name} (${businessId})`}</SubTitle>
      </Expander.Title>
      <Expander.Body id="content">
        <ContentSection top="md" style={{ background: 'white', padding: '8px' }}>
          {editable && layoutStore.isMobile && (
            <EditRemove businessId={organization.businessId} accountId={accountId} email={userEmail} inline />
          )}
          <MainUserRadioGroup
            disabled
            isMainUser={selectedRoleValues.includes('MainUser')}
            toggleUserRole={() => toggleUserRole('MainUser')}
          />
          <SelectRoles
            roleGroups={organization.roleGroups}
            selectedRoleValues={selectedRoleValues}
            disabled
            infoTranslationID="general.roleRequirementInfo2"
          />
          {customerNumbers && (
            <ExpanderContractNumberList
              title={t(`general.customerNumbers`)}
              options={addValuesToLabels(userBusinessPartnerNumbers, DropDownType.BUSINESS_PARTNER_NUMBER).concat(
                addValuesToLabels(userCustomerNumbers, DropDownType.CUSTOMER_NUMBER)
              )}
            />
          )}
          {logisticsContractNumbers && (
            <ExpanderContractNumberList
              title={t(`general.LCSNumbers`)}
              options={addValuesToLabels(userLCNumbers, DropDownType.LOGISTICS_CONTRACT_NUMBER)}
            />
          )}
          {transportIds && (
            <ExpanderContractNumberList
              title={t(`general.transportIds`)}
              options={addValuesToLabels(userTransportIds, DropDownType.TRANSPORT_ID)}
            />
          )}
          {principalIds && (
            <ExpanderContractNumberList
              title={t(`general.principalIds`)}
              options={addValuesToLabels(userPrincipalIds, DropDownType.PRINCIPAL_ID)}
            />
          )}
        </ContentSection>
      </Expander.Body>
    </Expander>
  )
}

export const OrganisationExpander = observer(OrganisationExpanderComponent)
