import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FilterDropdown, FilterServicesList, MainUserRadioGroup, SubTitle } from '../../components'
import { StoreContext } from '../../store'
import { DropDownType, IDropDownOption } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import { arrayIsEmpty } from '../../utils/helpers'

const FiltersComponent: React.FC = () => {
  const { searchStore } = useContext(StoreContext)

  const {
    searchMainUsers,
    organisationsOptions,
    selectedOrganisations,
    customerNumbers,
    selectedCustomerNumbers,
    LSCN,
    selectedLSCN,
    transportIds,
    selectedTransportIds,
    businessPartnerNumbers,
    selectedBusinessPartnerNumbers,
    principalIds,
    selectedPrincipalIds,
    toggleSearchMainUsers,
    setSelectedOrganisations,
    setSelectedCustomerNumbers,
    setSelectedLSCN,
    setSelectedTransportIds,
    setSelectedBusinessPartnerNumbers,
    setSelectedPrincipalIds,
  } = searchStore

  const [t] = useTranslation()

  const orgsToJS = toJS(organisationsOptions)
  const selectedOrganisationsToJS = toJS(selectedOrganisations)

  const orgsSelected = !arrayIsEmpty(selectedOrganisationsToJS)

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  const getCombinedCustomerNumbers = () => {
    const combinedNumbers = []
    const allNumbers = [...toJS(businessPartnerNumbers), ...toJS(customerNumbers)]

    for (const { label, options } of allNumbers) {
      const existingEntry = combinedNumbers.find((item) => item.label === label)

      if (existingEntry) {
        const uniqueOptions = new Set([...existingEntry.options, ...options])
        existingEntry.options = Array.from(uniqueOptions)
      } else {
        combinedNumbers.push({ label, options })
      }
    }

    return combinedNumbers
  }

  const setCombinedSelectedCustomerNumbers = (values: IDropDownOption[]) => {
    const enabledValues = values.filter((item) => !item.isDisabled)
    setSelectedCustomerNumbers(enabledValues.filter((item) => item.type === DropDownType.CUSTOMER_NUMBER))
    setSelectedBusinessPartnerNumbers(
      enabledValues.filter((item) => item.type === DropDownType.BUSINESS_PARTNER_NUMBER)
    )
  }

  return (
    <StyledFilters>
      <ContentSection top="lg">
        <SubTitle>{t(`filter.filter`)}</SubTitle>
      </ContentSection>
      <ContentSection top="md">
        <MainUserRadioGroup
          small
          horizontal
          plurarTranslations
          isMainUser={searchMainUsers}
          toggleUserRole={toggleSearchMainUsers}
        />
        <SubTitle>{t('filter.orgInfo')}</SubTitle>
        {/* TODO break thse into reusable own components ? */}
        <FilterDropdown
          id="organization_selector"
          key="organization_selector"
          options={orgsToJS}
          selectedOptions={selectedOrganisationsToJS}
          allowSelectAll
          isMulti
          allOption={allOption}
          placeHolderText={t('general.organisations')}
          onChange={setSelectedOrganisations}
        />

        <FilterDropdown
          id="customer_number_selector"
          key="customer_number_selector"
          disabled={!orgsSelected}
          grouped
          options={getCombinedCustomerNumbers()}
          selectedOptions={toJS(selectedBusinessPartnerNumbers.concat(selectedCustomerNumbers))}
          allowSelectAll
          isMulti
          allOption={allOption}
          placeHolderText={t('general.customerNumbers')}
          onChange={setCombinedSelectedCustomerNumbers}
        />

        <FilterDropdown
          id="lcsnumbers_selector"
          key="lcsnumbers_selector"
          disabled={!orgsSelected}
          grouped
          options={LSCN}
          selectedOptions={toJS(selectedLSCN)}
          allowSelectAll
          isMulti
          allOption={allOption}
          placeHolderText={t('general.LCSNumbers')}
          onChange={setSelectedLSCN}
        />

        <FilterDropdown
          id="transportid_selector"
          key="transportid_selector"
          disabled={!orgsSelected}
          grouped
          options={transportIds}
          selectedOptions={toJS(selectedTransportIds)}
          allowSelectAll
          isMulti
          allOption={allOption}
          placeHolderText={t('general.transportIds')}
          onChange={setSelectedTransportIds}
        />

        <FilterDropdown
          id="principalid_selector"
          key="principalid_selector"
          disabled={!orgsSelected}
          grouped
          options={principalIds}
          selectedOptions={toJS(selectedPrincipalIds)}
          allowSelectAll
          isMulti
          allOption={allOption}
          placeHolderText={t('general.principalIds')}
          onChange={setSelectedPrincipalIds}
        />
      </ContentSection>

      {orgsSelected && <FilterServicesList />}
    </StyledFilters>
  )
}

export const Filters = observer(FiltersComponent)

const StyledFilters = styled('div')`
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0; /* do not shrink - initial value: 1 */
  flex-basis: 400px;
  width: 400px;
`
