import { ListItem } from '@postidigital/posti-components'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { InviteList, MainTitle, OrganisationExpander, SubTitle, ViewLoading } from '../components'
import { EditRemove } from '../components/general/OrgExpander/EditDeleteComponent'
import { StoreContext } from '../store'
import { ContentSection } from '../style/layout'
import { breakpoint } from '../utils/breakpoint'
import { arrayIsEmpty } from '../utils/helpers'

const UserDetailViewComponent: FC = () => {
  const { t } = useTranslation()
  const { userDetailsStore, layoutStore } = useContext(StoreContext)
  const { id } = useParams()

  const { isLoading } = userDetailsStore

  useEffect(() => {
    userDetailsStore.fetchUserData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ViewLoading />
  }

  const { firstName, lastName, email, phone, userOrganizations, invites } = userDetailsStore

  return (
    <ContentSection gap="lg">
      <ContentSection>
        <MainTitle>{t('editUser.title')}</MainTitle>
        <StyledDetailsWrapper>
          <StyledListItem id="user_details_firstname" title={firstName} subtitle={t('general.firstName')} size="md" />
          <StyledListItem id="user_details_lastname" title={lastName} subtitle={t('general.lastName')} size="md" />
          <StyledListItem id="user_details_username" title={email} subtitle={t('invite.userUsername')} size="md" />
          <StyledListItem id="user_details_phone" title={phone} subtitle={t('general.phone')} size="md" />
          {/* TODO check do we want customerId shown? Not now */}
          {/* <StyledListItem title={phone} subtitle={'ohine'} size="md" /> */}
        </StyledDetailsWrapper>
      </ContentSection>
      <ContentSection>
        {toJS(userOrganizations).length > 0 && <SubTitle>{t('general.organisations')}</SubTitle>}

        {userOrganizations.map((org) => {
          return (
            <StyledOrgExpanderWrapper key={org.organization.businessId}>
              <OrganisationExpander
                organization={org.organization}
                selectedRoleValues={org.organizationUser.selectedRoles}
                selectedCustomerNumbers={org.organizationUser.selectedCustomerNumbers}
                selectedLCNumbers={org.organizationUser.selectedLogisticsContractNumbers}
                selectedTransportIds={org.organizationUser.selectedTransportIds}
                selectedBusinessPartnerNumbers={org.organizationUser.selectedBusinessPartnerNumbers}
                selectedPrincipalIds={org.organizationUser.selectedPrincipalIds}
                editable
                accountId={org.organizationUser.accountId}
                userEmail={org.organizationUser.email}
              />

              {!layoutStore.isMobile && (
                <EditRemove
                  businessId={org.organization.businessId}
                  accountId={org.organizationUser.accountId}
                  email={org.organizationUser.email}
                />
              )}
            </StyledOrgExpanderWrapper>
          )
        })}
        {!arrayIsEmpty(invites) && <InviteList invites={invites} />}
      </ContentSection>
    </ContentSection>
  )
}

export const UserDetailView = observer(UserDetailViewComponent)

const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`

const StyledOrgExpanderWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 16px;
`

const StyledListItem = styled(ListItem)`
  width: 50%;
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`
