// interfaces that have to be shared in many files
// If a interface is only used in one file, keep it in the file
export interface IUser {
  accountId: string
  firstName: string
  lastName: string
  organizations: IOrganizationItem[]
}

export interface IOrgUser {
  firstName: string
  lastName: string
  email: string
  organizationUserId: string
  selectedRoles: string[]
  selectedCustomerNumbers: string[]
  selectedLogisticsContractNumbers: string[]
  selectedTransportIds: string[]
  selectedBusinessPartnerNumbers: string[]
}

export interface IUserDetailOrganisation {
  organization: IOrganizationItem
  organizationUser: IOrganizationUser
}

export interface IOrganizationUser {
  accountId: string
  organizationUserId: string
  firstName: string
  lastName: string
  email: string
  selectedRoles: string[]
  selectedCustomerNumbers: string[]
  selectedLogisticsContractNumbers: string[]
  selectedTransportIds: string[]
  selectedBusinessPartnerNumbers: string[]
  selectedPrincipalIds: string[]
}
export interface IOrganizationItem {
  name: string
  businessId: string
  roleGroups: IRoleGroupModel[]
  customerNumbers?: IDropDownOption[]
  logisticsContractNumbers?: IDropDownOption[]
  transportIds?: IDropDownOption[]
  businessPartnerNumbers?: IDropDownOption[]
  principalIds?: IDropDownOption[]
}

export interface IRoleGroupModel {
  name: ILocalizedNameModel
  value: string
  roles: IRoleModel[]
}

export interface ILocalizedNameModel {
  en: string
  fi: string
  sv: string
}

export interface IRoleModel {
  name: ILocalizedNameModel
  value: string
  selectionDisabled: boolean
  customerNumberRequired: boolean
  logisticsContractNumberRequired: boolean
  transportIdRequired: boolean
  businessPartnerNumberRequired: boolean
  principalIdRequired: boolean
}

export interface IInvite {
  inviteId: string
  email: string
  organization: IOrganizationItem
  roleGroups: IRoleGroupModel[]
  expired: boolean
  created: string
  expires: string
  accepted: string | null
  cancelled: string | null
  language: string
}

export enum DropDownType {
  CUSTOMER_NUMBER = 1,
  LOGISTICS_CONTRACT_NUMBER,
  TRANSPORT_ID,
  BUSINESS_PARTNER_NUMBER,
  PRINCIPAL_ID,
  ORGANIZATION,
  SELECT_ALL,
}

export interface IDropDownOption {
  value: string
  label: string
  type: DropDownType
  isDisabled?: boolean
}

export interface INumberOption {
  value: string
  label: string
  disabled?: boolean
}

export interface IGroupedDropDownOptions {
  label: string
  options: IDropDownOption[]
}

export interface IFeatureFlags {
  [name: string]: boolean
}

export interface IPostiEnv {
  env: string
  features: IFeatureFlags
}
